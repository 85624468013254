import { graphql, useStaticQuery } from 'gatsby';
import { simpleFormat } from '../utils';
import { Retail, FeaturedImage } from '../types';

export type QueryResponse = {
  allWpRetail: {
    edges: {
      node: {
        id: string;
        title: string;
        acf: {
          address: string;
          addressUrl: string;
          openDate: string;
          tel: string;
          region: string;
        };
        featuredImage: {
          node: FeaturedImage;
        };
      };
    }[];
  };
};

export const useRetailsQuery = (stateLocation:String): Retail[] => {
  const { allWpRetail } = useStaticQuery<QueryResponse>(graphql`
    query RetailsQuery {
      allWpRetail(sort: { fields: dateGmt, order: ASC }) {
        edges {
          node {
            id
            title
            acf {
              address
              addressUrl
              openDate
              tel
              region
            }
            ...RetailImage
          }
        }
      }
    }
  `);

  return allWpRetail.edges.filter(function(node) {
    if (stateLocation != "0" && node.node.acf.region == stateLocation) {
      return true
    }
    else if (stateLocation == "0") {
      return true
    }
    
    return false
  }).map(({ node }) => {
    const {
      id,
      title,
      featuredImage: { node: thumbnail },
      acf: { address, addressUrl, openDate, tel },
    } = node;

    return {
      id,
      address,
      addressUrl,
      tel,
      name: title,
      businessHours: simpleFormat(openDate),
      thumbnail: thumbnail && {
        title: thumbnail.title,
        alt: thumbnail.altText,
        fluid: thumbnail.localFile.childImageSharp.fluid,
      },
    };
  });
};
