import React, { useState } from 'react';
import Img from 'gatsby-image';
import { Box, Flex, Heading, Text } from 'rebass/styled-components';
import { useRetailsQuery } from '../queries/useRetailsQuery';
import LocationIcon from '../assets/icons/location.svg';
import { PageHeading, ExternalLink } from '../components/';
import Layout from '../components/Layout';
import { Select } from '@rebass/forms/styled-components';
import '../styles/Map.css'
import { FaChevronDown } from "react-icons/fa";
import {graphql} from 'gatsby';
import Seo from 'gatsby-plugin-wpgraphql-seo';


const Map = (pageContext: any) => {
  const allWpRetail = pageContext.data.allWpRetail;
  const [stateLocation, setStateLocation] = useState('0');
  const retails = useRetailsQuery(stateLocation);

  const clickDropDown = () => {
    let menuContent : any = document.querySelector('.dropdown-content');
    if ( menuContent === null || menuContent.style === null) {
      return
    }
    
    if ( menuContent.style.display === 'none' ) {
      menuContent.style.display = 'block';
    }
    else if (menuContent.style.display === 'block') {
      menuContent.style.display = 'none';
    }
    else {
      menuContent.style.display = 'block';
    }
  };

  return (
    <Layout>
      {allWpRetail.edges.map( (edge : any) => {
        <Seo post={edge.node} />
      })}

      <Box variant="container">
        <PageHeading subTitle="Map">診所地圖</PageHeading>
          <Box className="dropdown" mb={80}>
            <button className="dropbtn" onClick={clickDropDown}>
              選擇地區
              <FaChevronDown className="arrow-down" />
            </button>
            <Box className="dropdown-content" >
              {[
                { value: '0', text: '全部'} ,
                { value: '1', text: '北'},
                { value: '2', text: '中'},
                { value: '3', text: '南'},
              ].map(({ value, text }) => (
                <a key={value} onClick={ (e) => setStateLocation(value) }>
                  {text}
                </a>
              ))}
            </Box>
          </Box>
        {retails.map(
          ({
            id,
            name,
            tel,
            address,
            addressUrl,
            businessHours,
            thumbnail,
          }) => (
            <Flex key={id} mb={['40px', '88px']} flexDirection="column">
              {thumbnail && (
                <Box
                  pt={[null, '16px']}
                  pl={[null, '40px']}
                  sx={{
                    order: [null, 1],
                    width: [null, 'calc(100% - 250px)'],
                    alignSelf: [null, 'flex-end'],
                    borderLeftStyle: [null, 'solid'],
                    borderLeftWidth: [null, '1px'],
                    borderLeftColor: [null, 'dark'],
                  }}
                >
                  <Img {...thumbnail} />
                </Box>
              )}
              <Flex flexDirection={['column', 'row']}>
                <Box width={[null, '250px']}>
                  <Heading
                    mt={['8px', 0]}
                    mb={['8px', '16px']}
                    pr={[null, null, '32px']}
                    fontFamily="serif"
                    fontSize={['18px', name.length > 5 ? '28px' : '39px']}
                    color="purple"
                    sx={{
                      wordBreak: 'break-word',
                    }}
                  >
                    {name}
                  </Heading>
                  <ExternalLink
                    display="inline-flex"
                    p={['4px 6px', '8px 10px']}
                    mb="16px"
                    bg="background.light"
                    color="dark"
                    fontSize={['10px', '16px']}
                    href={addressUrl}
                    sx={{
                      textDecoration: 'none',
                      alignItems: 'center',
                    }}
                  >
                    <LocationIcon width="18px" height="18px" />
                    <Text ml={4}>查看地圖</Text>
                  </ExternalLink>
                </Box>
                <Box
                  pl={[null, '40px']}
                  fontSize={['12px', '18px']}
                  sx={{
                    borderLeftStyle: [null, 'solid'],
                    borderLeftWidth: [null, '1px'],
                    borderLeftColor: [null, 'dark'],
                  }}
                >
                  <Text as="h5">{address}</Text>
                  <Text
                    as="h5"
                    my="16px"
                    dangerouslySetInnerHTML={{ __html: businessHours }}
                  />
                  <Text as="h5">{tel}</Text>
                </Box>
              </Flex>
            </Flex>
          ),
        )}
      </Box>
    </Layout>
  );
};
if (typeof window !== 'undefined') {
  window.onclick = function (event : any) {
    if (!event.target.matches('.dropbtn')) {
      let menuContent : any = document.querySelector('.dropdown-content');
      if ( menuContent === null || menuContent.style === null) {
        return
      }
      menuContent.style.display = 'none';
    }
  }
}

export default Map;

export const pageQuery = graphql`
  query GET_PAGE_RETAIL {
    allWpRetail {
      edges {
        node {
          seo {
            title
            metaDesc
            focuskw
            metaKeywords
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphTitle
            opengraphDescription
            opengraphImage {
              altText
              sourceUrl
              srcSet
            }
            twitterTitle
            twitterDescription
            twitterImage {
              altText
              sourceUrl
              srcSet
            }
            canonical
            cornerstone
            schema {
              articleType
              pageType
              raw
            }
          }
        }
      }
    }
  }
`;